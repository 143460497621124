import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "@fontsource/exo-2"
import "../components/pageStyles/_Index.scss"

import BackgroundVideoDay_0 from "../assets/vids/24934223-day.ogv"
import BackgroundVideoDay_1 from "../assets/vids/24934223-day.webm"
import BackgroundVideoDay_2 from "../assets/vids/24934223-day.mp4"
import BackgroundImageDay from "../assets/pics/24934223-snap.jpg"

import BackgroundVideoNight_0 from "../assets/vids/24934220-night.ogv"
import BackgroundVideoNight_1 from "../assets/vids/24934220-night.webm"
import BackgroundVideoNight_2 from "../assets/vids/24934220-night.mp4"
import BackgroundImageNight from "../assets/pics/24934220-snap.jpg"

const date = new Date();
const hour = date.getHours();
const texte = {
  0: {
    text: 'Wir entwickeln Webseiten und Webapplikationen',
    show_class: 'down-in',
    hide_class: 'down-out',
  },
  1: {
    text: 'Wir haben über 20 Jahre Erfahrung',
    show_class: 'down-in',
    hide_class: 'up-out',
  },
  2: {
    text: 'Wir setzen große und kleine Projekte um',
    show_class: 'up-in',
    hide_class: 'up-out',
  },
  3: {
    text: 'Wir übernehmen die komplette Projektabwicklung',
    show_class: 'up-in',
    hide_class: 'down-out',
  },
  4: {
    text: 'Wir haben unseren Standort in Bregenz (Vorarlberg)',
    show_class: 'down-in',
    hide_class: 'down-out',
  },
}

class IndexPage extends React.Component {
    constructor(props){
      super(props);
      var daytime = (hour > 5 &&  hour < 18);
      
      this.state = {
        shownIdx: 0,
        shownText: texte[0].text,
        shownClass: texte[0].show_class,
        backgroundImage: (daytime ? BackgroundImageDay : BackgroundImageNight),
        backgroundVideo_0: (daytime ? BackgroundVideoDay_0 : BackgroundVideoNight_0),
        backgroundVideo_1: (daytime ? BackgroundVideoDay_1 : BackgroundVideoNight_1),
        backgroundVideo_2: (daytime ? BackgroundVideoDay_2 : BackgroundVideoNight_2),
      }
    }

    updateClass = () => {
      this.updateClassTimeout =  setInterval(() => {
        this.setState({
          shownClass: texte[this.state.shownIdx].hide_class
        });
        this.updateTextTimeout = setTimeout(() => { 
          this.updateText();
        }, 600);
      }, 4200);
    }

    updateText = () => {
      var i = this.state.shownIdx + 1;
      if (i >= Object.keys(texte).length) i = 0;
      this.setState({ 
        shownIdx: i,
        shownText: texte[i].text,
        shownClass: texte[i].show_class
      });
    }

    componentDidMount() {
      this.updateClass();
    }
  
    componentWillUnmount() {
      if(this.updateClassTimeout) clearInterval(this.updateClassTimeout)
      if(this.updateTextTimeout) clearTimeout(this.updateTextTimeout)
    }

    render () {
      return (
        <Layout transparentnav>
          <Seo title="Webseiten & Web Applikationen" />
          <section className="oneScreen text_huge">
            <video id="indexBackground" poster={this.state.backgroundImage} className="middleScreen" autoPlay loop muted playsInline="playsInline">
              <source src={this.state.backgroundVideo_1} type='video/webm' />
              <source src={this.state.backgroundVideo_0} type='video/ogg; codecs="theora, vorbis"'/>
              <source src={this.state.backgroundVideo_2} type='video/mp4' />
            </video>
            <div id="indexTextBox" className={this.state.shownClass}>
              <h1>{this.state.shownText}</h1>
            </div>
          </section>
        </Layout>
    )
  }
}

export default IndexPage